import styled, { css} from "styled-components"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import {
  Body,
  SmallText,
  TextLink,
} from "ggx-componentlibrary/design/typography/typography.styles"
import { FONT_SIZE } from "ggx-componentlibrary/design/typography/typography.constants"
import { ORDER_DETAIL_WIDTH } from "../../order-detail.component.styles"
import { Tooltip } from "ggx-componentlibrary/components/tooltip/tooltip.component"
import { ContentBox } from "ggx-componentlibrary/components/tooltip/tooltip.component.styles"

const SubText = styled(SmallText)`
  margin-bottom: 0;
`

const StyledBody = styled(Body)`
  margin-bottom: 8px;
`

const EpodContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const EpodImage = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 4px;
  // margin-top: -24px;
  object-fit: contain;
  border-radius: 4px;
  border: 1px solid rgba(0, 18, 26, 0.10);
  visibility: ${props => props.$display ? 'visible' : 'hidden' };

  &:last-child {
    margin-right: 0;
  }
`

const EpodSignatureImage = styled.img`
  width: calc(${ORDER_DETAIL_WIDTH}px - 80px);
  height: 160px;
`

const StyledTooltip = styled(Tooltip)`
  ${ContentBox} {
    left: -40px;
  }
`

const Container = styled.div`
  margin-bottom: ${SPACING.L};
`

const StyledTextLink = styled(TextLink)`
  font-size: ${FONT_SIZE.MEDIUM};
`

const WaypointContent = styled.div`
  display: flex;
`

const WaypointContentLeft = styled.div`
  flex-grow: 1;
`

const WaypointContentRight = styled.div`
  flex: 0 0 125px;
`

const overlayClass = css`
  z-index: 9999;
`;

export {
  SubText,
  StyledBody,
  EpodImage,
  EpodSignatureImage,
  StyledTooltip,
  EpodContainer,
  Container,
  StyledTextLink,
  WaypointContent,
  WaypointContentLeft,
  WaypointContentRight,
  overlayClass,
}

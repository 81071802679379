import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  characterLimitValidator,
  addressLatLonValidator,
  requiredEmailFormat,
  HKTelephoneField,
  SGTelephoneField,
  TWTelephoneField,
  VNTelephoneField,
} from 'ggx-service/validation'
import { getCountry } from 'ggx-service/locale/country.service'
import { Modal } from 'ggx-componentlibrary/components/modal/modal.component'
import {
  StyledButton,
  FieldWrapper,
  Row,
  StyledTextField,
  Label,
  Rows,
} from './add-address-modal.component.styles'
import { AddressAutocomplete } from '../address-autocomplete/address-autocomplete.component'

import { useConvertToReduxFormProp } from 'ggx-global/hooks/useConvertToReduxFormProp'

const convertToReduxFormMeta = ({ isDirty, isTouched, error }) => ({
  dirty: isDirty,
  touched: isTouched,
  error: error?.message,
})

const AddAddressModal = ({
  recentOptions,
  defaultValues,
  onSubmit,
  onClose,
  isPlacingOrder,
  overlayClass,
  addressProps,
}) => {
  const [t] = useTranslation()
  const formProps = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues,
  })
  const {
    register,
    getFieldState,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting },
    getValues,
  } = formProps

  const [addressReduxFormProp] = useConvertToReduxFormProp(
    'address',
    formProps,
    useMemo(
      () => ({
        required: t('error__address_missing'),
        validate: {
          format: v =>
            addressLatLonValidator(t('error.invalid.address'))(
              v,
              undefined,
              undefined,
              'address'
            ),
        },
      }),
      [t]
    )
  )
  const [latReduxFormProp] = useConvertToReduxFormProp(
    'lat',
    formProps,
    useMemo(
      () => ({
        validate: {
          format: v =>
            addressLatLonValidator(t('error.invalid.address'))(
              v,
              undefined,
              undefined,
              'lat'
            ),
        },
      }),
      [t]
    )
  )
  const [lonReduxFormProp] = useConvertToReduxFormProp(
    'lon',
    formProps,
    useMemo(
      () => ({
        validate: {
          format: v =>
            addressLatLonValidator(t('error.invalid.address'))(
              v,
              undefined,
              undefined,
              'lon'
            ),
        },
      }),
      [t]
    )
  )
  const [
    detailAddressReduxFormProp,
    detailAddressInputProps,
  ] = useConvertToReduxFormProp(
    'detail_address',
    formProps,
    useMemo(
      () => ({
        validate: {
          format: characterLimitValidator(
            t('textfield__please_enter_characters_maximum', { number: 20 }),
            20
          ),
        },
      }),
      [t]
    )
  )
  const [
    contactNameReduxFormProp,
    contactNameInputProps,
  ] = useConvertToReduxFormProp(
    'contact_name',
    formProps,
    useMemo(
      () => ({
        validate: {
          format: characterLimitValidator(
            t('textfield__please_enter_characters_maximum', { number: 30 }),
            30
          ),
        },
      }),
      [t]
    )
  )
  const [
    contactPhoneNumberReduxFormProp,
    contactPhoneNumberInputProps,
  ] = useConvertToReduxFormProp(
    'contact_phone_number',
    formProps,
    useMemo(() => {
      const countryTelephoneValidator = (() => {
        switch (getCountry()) {
          case 'VN':
            return VNTelephoneField(t('error.invalid.contact.number'), true)
          case 'TW':
            return TWTelephoneField(t('error.invalid.contact.number'), true)
          case 'SG':
            return SGTelephoneField(t('error.invalid.contact.number'), true)
          case 'HK':
          default:
            return HKTelephoneField(t('error.invalid.contact.number'), true)
        }
      })()
      return {
        required: t('error__phone_missing'),
        validate: {
          format: countryTelephoneValidator,
        },
      }
    }, [t])
  )

  const footerButtons = useMemo(
    () => (
      <>
        <StyledButton buttonType="secondary" size="small" onClick={onClose}>
          {t('common__cancel')}
        </StyledButton>

        <StyledButton
          buttonType="primary"
          size="small"
          isLoading={isSubmitting}
          onClick={() => {
            if (!addressReduxFormProp.meta.touched) {
              addressReduxFormProp.input.onChange(
                addressReduxFormProp.input.value
              )
            }
            if (!isSubmitting) return handleSubmit(onSubmit)(getValues())
          }}
        >
          {isDirty && isPlacingOrder ? t('save_and_apply') : t('button__save')}
        </StyledButton>
      </>
    ),
    [handleSubmit, onSubmit, isDirty, isValid, isSubmitting, isPlacingOrder]
  )

  return (
    <Modal
      header={t('add_address')}
      footer={footerButtons}
      onRequestClose={onClose}
      width="600px"
      height="initial"
      maxHeight="610px"
      overlayClass={overlayClass}
    >
      <form>
        <FieldWrapper>
          <Label>{t('common__address')}</Label>
          <Row>
            <AddressAutocomplete
              address={addressReduxFormProp}
              lat={latReduxFormProp}
              lon={lonReduxFormProp}
              detail_address={detailAddressReduxFormProp}
              contact_name={contactNameReduxFormProp}
              contact_phone_number={contactPhoneNumberReduxFormProp}
              placeholder={t('street_address') + '*'}
              defaultOptions={recentOptions}
              otherProps={addressProps}
            />
            {useMemo(() => {
              return (
                <StyledTextField
                  name="detail_address"
                  placeholder={t('orders.new.basicInfo.placeholder.address2')}
                  meta={detailAddressReduxFormProp.meta}
                  inputProps={detailAddressInputProps}
                  disabled={
                    !addressReduxFormProp.input.value ||
                    !latReduxFormProp.input.value ||
                    !lonReduxFormProp.input.value
                  }
                />
              )
            }, [
              t,
              detailAddressReduxFormProp.meta,
              !addressReduxFormProp.input.value ||
                !latReduxFormProp.input.value ||
                !lonReduxFormProp.input.value,
            ])}
          </Row>
        </FieldWrapper>

        <FieldWrapper>
          <Label>{t('contact_person')}</Label>
          <Rows>
            {useMemo(() => {
              return (
                <StyledTextField
                  name="contact_phone_number"
                  placeholder={t('common__phone_number') + '*'}
                  meta={contactPhoneNumberReduxFormProp.meta}
                  inputProps={contactPhoneNumberInputProps}
                />
              )
            }, [t, Object.values(contactPhoneNumberReduxFormProp.meta)])}
            {useMemo(() => {
              return (
                <StyledTextField
                  name="contact_name"
                  placeholder={t('common__name')}
                  meta={contactNameReduxFormProp.meta}
                  inputProps={contactNameInputProps}
                />
              )
            }, [t, contactNameReduxFormProp.meta])}
            {useMemo(
              () => (
                <StyledTextField
                  name="email"
                  placeholder={t('common__email')}
                  meta={convertToReduxFormMeta(getFieldState('email'))}
                  inputProps={register('email', {
                    validate: {
                      format: v =>
                        Boolean(v)
                          ? requiredEmailFormat(t('error__email_format'))(v)
                          : undefined,
                    },
                  })}
                />
              ),
              [...Object.values(getFieldState('email')), register, t]
            )}
          </Rows>
        </FieldWrapper>

        <FieldWrapper>
          <Label>{t('address_nickname')}</Label>
          {useMemo(
            () => (
              <StyledTextField
                name="nickname"
                placeholder={t('eg_warehouse')}
                meta={convertToReduxFormMeta(getFieldState('nickname'))}
                inputProps={register('nickname', {
                  validate: {
                    format: v =>
                      characterLimitValidator(
                        t('textfield__please_enter_characters_maximum', {
                          number: 40,
                        }),
                        40
                      )(v),
                  },
                })}
              />
            ),
            [...Object.values(getFieldState('nickname')), register, t]
          )}
        </FieldWrapper>
      </form>
    </Modal>
  )
}

AddAddressModal.propTypes = {
  recentOptions: PropTypes.array,
  defaultValues: PropTypes.shape({
    address: PropTypes.string,
    lat: PropTypes.number,
    lon: PropTypes.number,
    detail_address: PropTypes.string,
    contact_name: PropTypes.string,
    contact_phone_number: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isPlacingOrder: PropTypes.bool,
  overlayClass: PropTypes.string,
}

export { AddAddressModal }

import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
  useImperativeHandle,
} from 'react'
import TextField from '@mui/material/TextField'
import AutoComplete from '@mui/material/Autocomplete'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import { isEmpty } from 'lodash'

import Options, { OPTION_TYPE } from './Options/options.component'
import { StyledError } from 'ggx-componentlibrary/components/text-field/text-field.component.styles'
import { COLOR } from 'ggx-componentlibrary/design/color/color.constants'
import { Loader } from 'ggx-componentlibrary/components/loader/loader.component'
import { getLocale } from '../../../../legacy/utils/locale'

export const CATEGORY = {
  ADDRESS: 'address',
  GENERIC: 'generic',
}

const getCustomComponentsProps = ({ menuWidth }) => {
  const componentsProps = {}

  if (menuWidth) {
    componentsProps.paper = {
      sx: {
        width: menuWidth,
      },
    }
  }

  return componentsProps
}

const getCustomListboxProps = ({ menuHeight }) => {
  const listboxProps = {}

  if (menuHeight) {
    listboxProps.sx = {
      maxHeight: menuHeight,
    }
  }

  return listboxProps
}

const getOptionAddresses = (options = []) => {
  return options.reduce((acc, option) => {
    let address = option.label ? option.label : option.value?.address
    if (address) {
      acc.push(address)
    }

    return acc
  }, [])
}

const MuiAutoComplete = ({
  category = CATEGORY.ADDRESS,
  selectedOption,
  defaultOptions,
  onQuery,
  onChange,
  onBlur,
  onInputChange,
  onFocus,
  onHover,
  menuWidth,
  menuHeight,
  placeholder,
  showDeleteAddressModal,
  showSaveAddressModal,
  supportEditSelectedValue = false,
  meta = {},
  dataTestid = 'autocomplete-search',
  customPopperComponent,
  endAdornment,
  className,
  componentRef,
}) => {
  const [searchText, setSearchText] = useState(selectedOption.value)
  useImperativeHandle(
    componentRef,
    () => ({
      clearInputValue: () => setSearchText(''),
    }),
    []
  )
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState(defaultOptions || [])
  const [open, setOpen] = useState(false)
  const openRef = useRef(open)
  const optionalProps = {}
  const componentsProps = getCustomComponentsProps({ menuWidth })
  const listboxProps = getCustomListboxProps({ menuHeight })
  const hasCustomComponentsProps = !isEmpty(componentsProps)
  const hasCustomListboxProps = !isEmpty(listboxProps)
  const optionAddresses = useMemo(() => {
    if (category !== CATEGORY.ADDRESS) return []
    return getOptionAddresses(options)
  }, [options])
  const prevSearchText = useRef()

  if (hasCustomComponentsProps) {
    optionalProps.componentsProps = componentsProps
  }

  if (hasCustomListboxProps) {
    optionalProps.ListboxProps = listboxProps
  }

  if (customPopperComponent) {
    optionalProps.PopperComponent = customPopperComponent
  }

  const handleInputChange = useCallback(
    e => {
      setSearchText(e.target.value)

      if (typeof onInputChange === 'function') {
        onInputChange(e.target.value)
      }

      setLoading(true)
    },
    [onInputChange]
  )

  const [lastTimeChange, setLastTimeChange] = useState()
  const handleOnChange = useCallback(
    (_, newValue) => {
      if (!onChange) return

      if (
        newValue.groupType === OPTION_TYPE.SAVED_ADDRESS ||
        newValue.groupType === OPTION_TYPE.SAVED_ADDRESS_BOOK
      ) {
        onChange(newValue)
      } else if (newValue.groupType === OPTION_TYPE.RECENT_ADDRESS) {
        onChange({
          groupType: newValue.groupType,
          ...newValue.value,
        })
      } else if (newValue.groupType === OPTION_TYPE.NDD_LOCATION) {
        const locale = getLocale()
        const newSearchText =
          locale === 'zh-hk'
            ? newValue.value.address_zh
            : newValue.value.address_en

        onChange(newValue.value)
      } else {
        onChange(newValue)
      }
      setLastTimeChange(Date.now())
      setOpen((openRef.current = false))
    },
    [onChange]
  )

  const handleOnOpen = useCallback(() => {
    setOpen((openRef.current = true))
  }, [])

  const handleOnClose = useCallback(() => {
    if (!onBlur) {
      setOpen((openRef.current = false))
      return
    }

    if (openRef.current) {
      if (
        category === CATEGORY.ADDRESS &&
        (!searchText || !optionAddresses.includes(searchText))
      ) {
        onBlur({
          address: searchText,
          lat: '',
          lon: '',
        })
      } else {
        onBlur({
          searchText,
        })
      }

      setOpen((openRef.current = false))
    }
  }, [searchText, optionAddresses, onBlur, category])

  const handleDeleteSavedAddress = useCallback(
    savedAddressId => {
      setOpen((openRef.current = false))
      showDeleteAddressModal(savedAddressId)
    },
    [showDeleteAddressModal]
  )

  useEffect(() => {
    if (!searchText || !onQuery) {
      loading && setLoading(false)
      return
    }

    if (!loading && prevSearchText.current === searchText) {
      // Prevent trigger another query when loading state from true into false.
      return
    }

    prevSearchText.current = searchText

    Promise.resolve(onQuery(searchText))
      .then(options => {
        setOptions(options || [])
        setLoading(false)
      })
      .catch(e => {
        console.warn('onQuery error with searchText: ', searchText)
        console.warn('onQuery error: ', e)
      })
  }, [searchText, onQuery, loading])

  useEffect(() => {
    if (!searchText) {
      // When search input is empty should set default options as options
      setOptions(defaultOptions)
    } else if (
      options.length === 1 &&
      (options[0].groupType === OPTION_TYPE.SAVE_ADDRESS ||
        options[0].groupType === OPTION_TYPE.SAVE_ADDRESS_BOOK)
    ) {
      // When search input not empty but no result from server should give user a hint.
      setOptions([
        ...options,
        {
          groupType: OPTION_TYPE.NO_LOCATION,
          label: 'No locations',
        },
      ])
    }
  }, [searchText, options, defaultOptions])

  useEffect(() => {
    setSearchText(selectedOption.value)
  }, [selectedOption.value, selectedOption.lat, lastTimeChange])

  return (
    // <ClickAwayListener onClickAway={handleOnClose} disableReactTree>
    <AutoComplete
      className={className}
      data-testid={dataTestid}
      open={open}
      autoHighlight
      disableCloseOnSelect
      disableClearable
      freeSolo={supportEditSelectedValue}
      options={options}
      renderOption={(muiProps, option, state) => {
        return (
          <Options
            muiProps={{
              ...muiProps,
              onMouseDown: muiProps.onClick,
              onMouseMove: () => onHover && onHover(option),
              onMouseLeave: () => onHover && onHover(),
            }}
            option={option}
            handleDeleteSavedAddress={handleDeleteSavedAddress}
            showSaveAddressModal={showSaveAddressModal}
            key={`${muiProps.key}${muiProps.id}`}
          />
        )
      }}
      getOptionLabel={option => {
        const valueObject = option.value
        let label = option.label ? option.label : valueObject?.address

        return label || ''
      }}
      getOptionDisabled={option => {
        return option.groupType === OPTION_TYPE.NO_LOCATION
      }}
      /**
       * this filterOptions override is necessary for searching as you type
       * see: https://mui.com/material-ui/react-autocomplete/#search-as-you-type
       */
      filterOptions={x => x}
      onChange={handleOnChange}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      inputValue={searchText}
      value={selectedOption}
      isOptionEqualToValue={useCallback((option, value) => {
        if (value.groupType === 'saved-address-book') {
          return (
            option.groupType === value.groupType &&
            (option.value?.address === value.address ||
              option.label === value.address) &&
            option.value.detail_address === value.detail_address &&
            option.value.contact_name === value.contact_name &&
            option.value.contact_phone_number === value.contact_phone_number
          )
        }
        return (
          option.groupType === value.groupType &&
          (option.value?.address === value.address ||
            option.label === value.address)
        )
      }, [])}
      loading={loading}
      renderInput={params => {
        const error = meta && meta.touched && meta.error
        const inputBorderStyle = {
          borderColor: !loading && error ? COLOR.RED_MEDIUM : COLOR.GREY_LIGHT,
        }
        const optionalInputProps = {}

        if (onFocus) {
          optionalInputProps.onFocus = onFocus
        }

        return (
          <>
            <TextField
              {...params}
              data-error-field={!!error}
              placeholder={placeholder ?? 'Select...'}
              onChange={handleInputChange}
              InputProps={{
                ...params.InputProps,
                sx: {
                  '&:hover': {
                    '& .MuiOutlinedInput-notchedOutline': inputBorderStyle,
                  },
                  '& .MuiOutlinedInput-notchedOutline': inputBorderStyle,
                  '& input::placeholder': {
                    color: COLOR.GREY_DARK,
                    opacity: 1,
                    fontSize: '14px',
                  },
                  '.MuiFormControl-root &.MuiAutocomplete-inputRoot': {
                    padding: '0 16px 0 10px',
                  },
                  '.MuiFormControl-root &.MuiAutocomplete-inputRoot input': {
                    paddingRight: '16px',
                  },
                },
                endAdornment: (
                  <>
                    {loading ? (
                      <Loader data-testid="autocomplete-loader" />
                    ) : null}
                    {endAdornment}
                  </>
                ),
              }}
              {...optionalInputProps}
            />
            {!loading && error && <StyledError>{meta.error}</StyledError>}
          </>
        )
      }}
      {...optionalProps}
    />
    // </ClickAwayListener>
  )
}

export default MuiAutoComplete

/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import pDebounce from 'p-debounce'
import { isEmpty } from 'lodash'

import MuiAutoComplete from 'ggx-componentlibrary/components/autocomplete/mui-autocomplete/mui-autocomplete.component'
import { placesService } from 'ggx-service/api/places.service'

const AddressAutocomplete = props => {
  const {
    address,
    lat,
    lon,
    detail_address,
    contact_name,
    contact_phone_number,
    placeholder,
    defaultOptions,
    otherProps,
  } = props

  const value = {
    value: address.input.value,
    address: address.input.value,
    lat: lat.input.value,
    lon: lon.input.value,
  }

  const meta = {
    touched: address.meta.touched || lat.meta.touched || lon.meta.touched,
    error: address.meta.error || lat.meta.error || lon.meta.error,
  }

  const debouncedPlacesAutoComplete = pDebounce(
    placesService.placesAutocomplete,
    300
  )

  const handleAutocompleteQuery = async queryString => {
    try {
      const response = await debouncedPlacesAutoComplete(queryString)

      if (response.length === 0) return []

      const locationResults = (response || []).map(location => ({
        value: location,
        label: location.description,
        groupType: 'location',
      }))

      return locationResults
    } catch (error) {
      return []
    }
  }

  // eslint-disable-next-line no-shadow
  const handleOnBlur = value => {
    if (!address.input || !lat.input || !lon.input) {
      return
    }

    const onBlurHandlers = {
      address: address.input.onBlur,
      lat: lat.input.onBlur,
      lon: lon.input.onBlur,
    }

    onBlurHandlers.address(value.address)
    onBlurHandlers.lat(value.lat)
    onBlurHandlers.lon(value.lon)
  }

  const handleAutocompleteChange = async (selectedItem = {}) => {
    const invokeChangeHandlers = values => {
      Object.assign(values, {
        detail_address: detail_address.input.value,
        contact_name: contact_name.input.value,
        contact_phone_number: contact_phone_number.input.value,
      })
      address.input.onChange(values.address)
      lat.input.onChange(values.lat)
      lon.input.onChange(values.lon)
      detail_address.input.onChange(values.detail_address)
      contact_name.input.onChange(values.contact_name)
      contact_phone_number.input.onChange(values.contact_phone_number)
    }

    if (isEmpty(selectedItem)) {
      invokeChangeHandlers()
      return
    }

    const { groupType } = selectedItem

    if (groupType === 'recent-address') {
      invokeChangeHandlers({
        address: selectedItem.address,
        lat: selectedItem.lat,
        lon: selectedItem.lon,
        detail_address: selectedItem.detail_address,
        contact_name: selectedItem.contact_name,
        contact_phone_number: selectedItem.contact_phone_number,
      })
    } else if (groupType === 'location') {
      try {
        invokeChangeHandlers({
          address: selectedItem.label,
        })

        const { place_id: placeId } = selectedItem.value
        const { geometry = {} } = await placesService.placesDetails(placeId)
        const { lat: latitude, lng: longitude } = geometry.location || {}

        invokeChangeHandlers({
          address: selectedItem.label,
          lat: latitude,
          lon: longitude,
        })
      } catch (error) {
        invokeChangeHandlers()
      }
    }
  }

  return (
    <MuiAutoComplete
      selectedOption={value}
      onQuery={handleAutocompleteQuery}
      onChange={handleAutocompleteChange}
      onBlur={handleOnBlur}
      defaultOptions={defaultOptions}
      menuWidth={400}
      menuHeight={180}
      placeholder={placeholder}
      supportEditSelectedValue
      meta={meta}
      {...otherProps}
    />
  )
}

const reduxFormPropTypeShape = PropTypes.shape({
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
})

AddressAutocomplete.propTypes = {
  address: reduxFormPropTypeShape,
  lat: reduxFormPropTypeShape,
  lon: reduxFormPropTypeShape,
  detail_address: reduxFormPropTypeShape,
  contact_name: reduxFormPropTypeShape,
  contact_phone_number: reduxFormPropTypeShape,
  placeholder: PropTypes.string,
  defaultOptions: PropTypes.oneOfType([
    PropTypes.shape({
      then: PropTypes.func,
      catch: PropTypes.func,
    }),
    PropTypes.array,
  ]),
}

export { AddressAutocomplete }

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import Lightbox from 'react-image-lightbox'

import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'
import { formatDateTime } from 'ggx-service/date-time/format-date-time'
import { Waypoint } from './waypoint.component'
import {
  Body,
  BodySemiBold,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import {
  SubText,
  StyledBody,
  EpodImage,
  EpodSignatureImage,
  StyledTooltip,
  EpodContainer,
  Container,
  StyledTextLink,
  WaypointContent,
  WaypointContentRight,
  WaypointContentLeft,
  overlayClass,
} from './route-info.component.styles'
import { COLOR } from 'ggx-componentlibrary/design/color/color.constants'
import AddSVG from './assets/add.svg'
import { AddAddressModal } from 'ggx-src/address-book/add-address-modal/add-address-modal.component'
import { apiService as deliveryService } from 'ggx-src/delivery/api/api.service'
import uniqBy from 'lodash/uniqBy'
import { displayAlert } from 'ggx-componentlibrary/components/alerts/alerts.component'
import { addressBookService } from 'ggx-src/address-book/api/api.service'
import Popper from '@mui/material/Popper'

const EpodSignature = ({ signatureImage, signer }) => {
  const [t] = useTranslation()

  return (
    <StyledTooltip
      showTooltipIcon={false}
      isHeadingHoverable
      heading={<StyledTextLink>{t('text__show_signature')}</StyledTextLink>}
      content={
        <EpodContainer>
          <EpodSignatureImage
            src={signatureImage.medium}
            alt="epod signature"
          />
          {signer && <Body>{t('text__recipient_name', { name: signer })}</Body>}
        </EpodContainer>
      }
    />
  )
}

const EpodImages = ({ epodImages, handleOnClick }) => {
  return (
    <WaypointContentRight>
      <EpodImage
        src={epodImages.signature.medium}
        $display={!!epodImages?.signature?.medium}
        alt="epod signature"
        onClick={() => handleOnClick(0)}
      />
      <EpodImage
        src={epodImages.photo.medium}
        $display={!!epodImages?.photo?.medium}
        alt="epod photo"
        onClick={() => handleOnClick(1)}
      />
    </WaypointContentRight>
  )
}

const EpodLightbox = ({ epodImages }) => {
  let images = [epodImages?.signature?.medium, epodImages?.photo?.medium]
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const nextIndex = images[photoIndex + 1] && photoIndex + 1
  const prevIndex = images[photoIndex - 1] && photoIndex - 1

  const handleOnClick = imgIndex => {
    setIsOpen(true)
    setPhotoIndex(imgIndex)
  }

  return !isEmpty(epodImages) ? (
    <>
      <EpodImages epodImages={epodImages} handleOnClick={handleOnClick} />
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[nextIndex]}
          prevSrc={images[prevIndex]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex(prevIndex)}
          onMoveNextRequest={() => setPhotoIndex(nextIndex)}
          animationOnKeyInput
          enableZoom={true}
          reactModalStyle={{
            overlay: {
              zIndex: 1201,
            },
          }}
          wrapperClassName="customize-react-image-lightbox"
        />
      )}
    </>
  ) : null
}

const RouteInfo = ({ waypoints, pickupTime, orderContactInfo = {} }) => {
  const [t] = useTranslation()
  const featureFlagTransportEpodPhoto = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'transport_epod_photo' })
  )

  const getWaypointLabel = (waypointIndex, waypointCount) => {
    if (waypointIndex === 0) return t('common__pick_up')
    if (waypointCount > 2)
      return t('common__drop-off_value', { value: waypointIndex })

    return t('common__drop_off')
  }

  const waypointsList = waypoints.map((waypoint, index) => {
    const isFirstWaypoint = index === 0
    const isLastWaypoint = index === waypoints.length - 1
    const label = getWaypointLabel(index, waypoints.length)
    const contactName = waypoint.contact_name
    const contactPhoneNumber =
      waypoint.contact_phone_number && `(${waypoint.contact_phone_number})`
    const [recentOptions, setRecentOptions] = useState([])
    useEffect(() => {
      deliveryService
        .getContacts()
        .catch(() => ({ contacts: [] }))
        .then(result => {
          const options = result.contacts.map(item => ({
            value: {
              address: item.address,
              lat: Number(item.latitude),
              lon: Number(item.longitude),
            },
            groupType: 'recent-address',
          }))
          setRecentOptions(
            uniqBy(options, ({ value }) => `${value.lat}-${value.lon}`)
          )
        })
    }, [])
    const [showAddDialogForWaypoint, setShowAddDialogForWaypoint] = useState(
      false
    )
    const addAddress = async value => {
      try {
        await addressBookService.addAddress(value)
        displayAlert({ message: t('address_saved') })
        setShowAddDialogForWaypoint(false)
      } catch (error) {
        console.error(error)
      }
    }
    return (
      <>
        <Waypoint
          key={`styledWaypoint_${index}`}
          titleLabel={<BodySemiBold noMargin>{label}</BodySemiBold>}
          isStopover={!isFirstWaypoint && !isLastWaypoint}
          isDestination={isLastWaypoint}
          trailColor={COLOR.GREY_LIGHT}
          trailSolid
          iconRight={
            <AddSVG onClick={() => setShowAddDialogForWaypoint(true)} />
          }
        >
          <WaypointContent>
            <WaypointContentLeft>
              <StyledBody>{waypoint.address}</StyledBody>
              <SubText>
                {isFirstWaypoint
                  ? contactName || orderContactInfo.name
                  : contactName}{' '}
                {isFirstWaypoint
                  ? contactPhoneNumber || orderContactInfo.phoneNumber
                  : contactPhoneNumber}
              </SubText>
              <SubText>{isFirstWaypoint && formatDateTime(pickupTime)}</SubText>
              {!isEmpty(waypoint.epod_image?.signature) &&
              !featureFlagTransportEpodPhoto ? (
                <EpodSignature
                  signatureImage={waypoint.epod_image?.signature}
                  signer={waypoint.signer}
                />
              ) : null}
            </WaypointContentLeft>
            {featureFlagTransportEpodPhoto ? (
              <EpodLightbox epodImages={waypoint.epod_image} />
            ) : null}
          </WaypointContent>
        </Waypoint>
        {showAddDialogForWaypoint ? (
          <AddAddressModal
            recentOptions={recentOptions}
            defaultValues={waypoint}
            onSubmit={addAddress}
            onClose={() => setShowAddDialogForWaypoint(null)}
            overlayClass={overlayClass}
            addressProps={{
              customPopperComponent: ({ style, ...props }) => {
                return <Popper style={{ ...style, zIndex: 99999 }} {...props} />
              },
            }}
          />
        ) : null}
      </>
    )
  })

  return <Container>{waypointsList}</Container>
}

RouteInfo.propTypes = {
  waypoints: PropTypes.arrayOf(PropTypes.object),
  pickupTime: PropTypes.string,
}

RouteInfo.defaultProps = {
  waypoints: [],
}

export { RouteInfo }
